import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
  Grid
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Page from 'src/components/Page';
import { StandardLoginForm, NewLoginForm, ForgotPasswordModal } from './LoginFormParts';

import { useRecoilState } from 'recoil';
import { loggedInUserAtom } from 'src/app-data/atoms/user-atom';

import Amplify, { Auth } from "aws-amplify";
import { config } from 'src/config';

const _values = config();

Amplify.configure({
  Auth: {
    region: _values.region,
    userPoolId: _values.userPoolId,
    userPoolWebClientId: _values.userPoolWebClientId
  }
});


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  forgot: {
    fontSize: "12px",
    color: "#3f51b5",
    padding: "0"
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [displayedMessage, updateDisplayedMessage] = useState("Login with email address");
  const [newPasswordRequired, updateNewPasswordRequired] = useState(false);
  const [_isSubmitting, updateIsSubmitting] = useState(false);
  const [userState, setUserState] = useRecoilState(loggedInUserAtom);

  const setupUserDetails = (attributes) => {
    setUserState({
      ...userState,
      ...attributes
    });
  };

  const [displayMessage, updateDisplayMessage] = useState({
    message: "",
    show: false,
    severity: "", //error, warning, info, success
    showAction: false
  });

  

  useEffect(() => {
    //Check to see if user is already logged in
    Auth.currentAuthenticatedUser()
      .then(usr => { 
        const { attributes } = usr;
        setupUserDetails(attributes);
        navigate('/app/dashboard', { replace: true });
      })
      .catch(err => {
        //do nothing
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: '',
              old_password: '',
              new_password: '',
              first_name: '',
              last_name: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required'),
              new_password: newPasswordRequired && Yup.string().max(255).required('New Password is required'),
              first_name: newPasswordRequired && Yup.string().max(255).required('First Name is required'),
              last_name: newPasswordRequired && Yup.string().max(255).required('Last Name is required')
            })}
            onSubmit={(e) => {
              const { email, password } = e;
              updateIsSubmitting(true);
              
              try {
                Auth.signIn({
                    username: email,
                    password: password
                }).then((user) => {
                  if(user.challengeName && user.challengeName === "NEW_PASSWORD_REQUIRED") {
                    updateDisplayedMessage("Change Password Required. [Requirments] Minimum length 8, Requires at least 1 of the following: Special character, upper and lower case letters and at least one number.");

                    if(!e.new_password) {
                      updateNewPasswordRequired(true);
                      updateIsSubmitting(false);
                    }
                    else {
                      Auth.completeNewPassword(
                          user, 
                          e.new_password,
                          {
                            family_name: e.last_name,
                            given_name: e.first_name
                          }
                      ).then(updatedUser => {
                        localStorage.setItem("access_token", updatedUser.signInUserSession.accessToken.jwtToken);
                        setupUserDetails(updatedUser.attributes);
                        navigate('/app/dashboard', { replace: true });
                      }).catch(e => {
                        console.log(e);
                      });
                    }
                  }
                  else {
                    localStorage.setItem("access_token", user.signInUserSession.accessToken.jwtToken);
                    setupUserDetails(user.attributes);
                    navigate('/app/dashboard', { replace: true });
                  }
                }).catch(er => {
                  updateIsSubmitting(false);

                  switch(er.code) {
                    case "PasswordResetRequiredException":
                      //only when reset from console
                      updateDisplayMessage({
                        message: `${er.message}\n Error Code 3a03f962-e108-4520-a4c6-8ac4884a2bf4`,
                        show: true,
                        severity: "error"
                      });
                      break;
                    case "NotAuthorizedException":
                      //If the temp password expires on a new user delete the user and recreate 
                      //in console. Cognito will send a new code.
                      updateDisplayMessage({
                        message: `${er.message}\n Error Code c734501f-752d-4618-9624-e52a3a865355`,
                        show: true,
                        severity: "error"
                      });
                      break;
                    default:
                      updateDisplayMessage({
                        message: `${er.message}\n Error Code 5eb98b95-7e31-45ee-8e13-48aaace1ec56`,
                        show: true,
                        severity: "error"
                      });
                      break;
                  }
                });
              }
              catch(error) {
                updateIsSubmitting(false);
                updateDisplayMessage({
                  message: `${error.message}\n Error Code e8d113ab-5cf4-4ca1-98a5-d27d602aa4de`,
                  show: true,
                  severity: "error"
                });
              }
            }}
          >
            {({errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    Discover The Wild Sign in
                  </Typography>

                  <Typography color="textSecondary" gutterBottom variant="body2">
                    Sign in to the Discover The Wild internal platform
                  </Typography>
                </Box>

                <Box mt={3} mb={1}>
                  <Typography align="center" color="textSecondary" variant="body1">
                    {displayedMessage}
                  </Typography>
                </Box>
                
                <StandardLoginForm 
                  touched={touched} 
                  values={values} 
                  errors={errors} 
                  handleBlur={handleBlur} 
                  handleChange={handleChange} 
                  newPasswordRequired={newPasswordRequired} 
                />
                
                <NewLoginForm 
                  touched={touched} 
                  values={values} 
                  errors={errors} 
                  handleBlur={handleBlur} 
                  handleChange={handleChange} 
                  newPasswordRequired={newPasswordRequired} 
                />
                
                <Box my={2}>
                  <Button 
                    color="primary"
                    disabled={_isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>

                <Box my={2}>
                  {displayMessage.show &&
                    <Alert severity={displayMessage.severity}>
                      {displayMessage.message}
                    </Alert>
                  }
                </Box>

                <Box mt={3} mb={1}>
                  <ForgotPasswordModal>
                    {
                      ({handleClickOpen}) => (
                        <Grid container justify="flex-end">
                          <Button variant="text" className={classes.forgot} onClick={handleClickOpen}>
                            Forgot Password
                          </Button>
                        </Grid>
                      )
                    }
                  </ForgotPasswordModal>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
