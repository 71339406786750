import React, { useState } from 'react';
import { 
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    makeStyles
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { Formik } from 'formik';
import * as Yup from 'yup';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { useRecoilCallback, useRecoilValue } from 'recoil';
import { availableTrodesAtom, shippedTrodesAtom, trodeSearchSerialNumberAtom } from 'src/app-data/atoms/trodes-atom';
import { useSetPulseVetApiState } from 'src/app-data/triggers/api-triggers';
import { httpClient } from 'src/lib/api-factory';
import { formatDate } from 'src/lib/date-functions';


const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        fontSize: "0.850rem"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200
    },
    textArea: {
        marginLeft: theme.spacing(1),
        width: "91%"
    }
}));

export const ReceiveTrodeModal = (props) => {
    const { trode } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [savedToDb, setSavedToDb] = useState(false);

    const [_isSubmitting, updateIsSubmitting] = useState(false);
    const [displayMessage, updateDisplayMessage] = useState({
        message: "",
        show: false,
        severity: ""
    });

    const refreshAvailableTrodes = useSetPulseVetApiState(availableTrodesAtom);
    const refreshShippedTrodes = useSetPulseVetApiState(shippedTrodesAtom);
    const serialNumber = useRecoilValue(trodeSearchSerialNumberAtom);

    const handleClickOpen = () => setOpen(true);

    const handleClose = (resetFunc) => {
        setOpen(false);
        handleCleanup(resetFunc);

        if(savedToDb) {
            refreshAvailableTrodes({ type: "GET", uri: "/trodes" });
            refreshShippedTrodes({ type: "GET", uri: serialNumber ? `/trodes-shipped?sn=${serialNumber}` : "/trodes-shipped" });
        }
    };

    const handleCleanup = (resetFunc) => {
        updateIsSubmitting(false);
        updateDisplayMessage({ message: "", show: false, severity: "" });
        
        if(typeof resetFunc === "function") {
            resetFunc();
        }
    };

    const getFromDb = useRecoilCallback(() => async (uri) => { 
        try {
            const response = await httpClient().get(uri);
            return response.data;
        }
        catch(error) {
            return error;
        }    
    });

    const postToDb = useRecoilCallback(() => async (uri, data) => { 
        try {
            const response = await httpClient().post(uri, data);
            return response.data;
        }
        catch(error) {
            return error;
        }    
    });

    const handleSubmit = (values, resetFunc, validateFunc, setTouched) => {
        const { dateReceived, notes } = values;

        (async () => { 
            try {
                const valError = await validateFunc();
                var validationErrors = false;

                Object.keys(valError).forEach(key => {
                    setTouched({
                        [key]: true
                    });
                }); 

                if(!validationErrors) { 
                    updateIsSubmitting(true);
                    
                    //Add new shipping record
                    var newShippingRecordResponse = await postToDb('/trodes-shipped', {
                        trodeId: trode.trodeId, 
                        customerAccountId: trode.customerAccountId, 
                        dateSentOut: trode.dateSentOut, 
                        dateReceivedIn: dateReceived
                    });

                    if(newShippingRecordResponse && newShippingRecordResponse.shipRecordId) {
                        if(notes && notes.trim() !== '') {
                            if(trode.parentId) {
                                var parent = await getFromDb(`/trodes/${trode.parentId}`);

                                var _notes;
                                if(parent.notes) {
                                    _notes = parent.notes;
                                }

                                _notes = _notes ?  _notes.concat(`\n`, notes.trim()) : notes.trim();

                                if(parent && parent.trodeId) {
                                    //get parent notes, concat new note into parent note
                                    await postToDb(`/trodes/${parent.trodeId}/alter`, {
                                        notes: _notes
                                    });
                                }
                            }
                        }

                        var allNotes;
                        if(trode.notes) {
                            allNotes = trode.notes;
                        }

                        if(notes && notes.trim() !== '') {
                            allNotes = allNotes ?  allNotes.concat(`\n`, notes.trim()) : notes.trim();
                        }

                        const DbNull = '';

                        //set Trode back to available
                        var updateTrodeResponse = await postToDb(`/trodes/${trode.trodeId}/alter`, {
                            customerAccountId: DbNull,
                            dateSentOut: DbNull,
                            notes: allNotes
                        });

                        if(updateTrodeResponse && updateTrodeResponse.trodeId) {
                            updateDisplayMessage({ 
                                message: "Trode has been received and will be added back to Available Trodes",
                                show: true,
                                severity: "success"
                            });
        
                            setSavedToDb(true);
        
                            if(typeof resetFunc === "function") {
                                resetFunc();
                            } 
                        }
                        else {
                            setSavedToDb(false);
                            updateIsSubmitting(false);

                            updateDisplayMessage({
                                message: `There was an error setting the Trode back to 'Available'. Serial Number: ${trode.serialNumber}`,
                                show: true,
                                severity: "error"
                            });
                        }
                        
                    }
                    else {
                        updateDisplayMessage({
                            message: "There was an error adding new shipping record. The Trode was not received.",
                            show: true,
                            severity: "error"
                        });
                    }
                }
            }
            catch(error) {
                console.log(error);
                updateDisplayMessage({
                    message: "There was an error receiving this Trode",
                    show: true,
                    severity: "error"
                });
            }
        })();
    };

    return (
        <>
            <Formik
                initialValues={{
                    dateReceived: formatDate(Date.now())
                }}
                validationSchema={Yup.object().shape({
                    dateReceived: Yup.date()
                        .required("Invalid date (ddmmyyyy)")
                        .min(trode.dateSentOut, "Received date can not be before shipped date")
                })}
            >
                {({errors, handleBlur, handleChange, handleReset, validateForm, setTouched, touched, values}) => (
                <>
                    {props.children && props.children({
                        handleClickOpen: handleClickOpen
                    })}

                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogContent>
                            <Card>
                                <CardHeader
                                    title={trode && `Receive Trode: ${trode.trodeTypeName} : ${trode.serialNumber} ${trode.trodeTypeSize}${trode.trodeTypeMeasurement}`}
                                    subheader={`Sent to ${trode.customerName} #${trode.customerNumber} on ${formatDate(trode.dateSentOut)}`}
                                />

                                <Divider />

                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Date Received"
                                                name="dateReceived"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                required
                                                type="date"
                                                SelectProps={{ native: true }}
                                                value={values.dateReceived || ''}
                                                variant="outlined"
                                                error={Boolean(touched.dateReceived && errors.dateReceived)}
                                                helperText={touched.dateReceived && errors.dateReceived}
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Notes"
                                                name="notes"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="text"
                                                SelectProps={{ native: true }}
                                                value={values.notes || ''}
                                                variant="outlined"
                                                error={Boolean(touched.notes && errors.notes)}
                                                helperText={touched.notes && errors.notes}
                                                className={classes.textArea}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                multiline
                                                rows={2}
                                                rowsMax={4}
                                            />
                                        </Grid>

                                        {displayMessage.show &&
                                        <Grid item md={12} xs={12}>
                                            <Alert severity={displayMessage.severity}>{displayMessage.message}</Alert>
                                        </Grid>
                                        }
                                    </Grid>
                                </CardContent>

                                <Divider />

                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                    p={2}
                                >
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => handleSubmit(values, handleReset, validateForm, setTouched)}
                                        disabled={_isSubmitting}
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </Card>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => handleCleanup(handleReset)} color="primary" disabled={_isSubmitting}>
                                Reset
                            </Button>

                            <Button onClick={() => handleClose(handleReset)} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
                )}
            </Formik>
        </>
    );
};