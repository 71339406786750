import React from 'react';

import {
  Typography,
  makeStyles
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {},
  logo: {
    fontSize: "2em",
    color: "#ffffff",
    fontFamily: "'Montserrat Alternates', sans-serif"
  }
}));

const Logo = (props) => {
  const classes = useStyles();

  return (
    // <img
    //   alt="Logo"
    //   src="https://cdn.pulsevettt.com/logos/logo_250x81.png"
    //   {...props}
    //   style={{width: "50%", height: "%0%", filter: "brightness(0) invert(1)"}}
    // />

    <Typography className={classes.logo}>
      Discover The Wild
    </Typography>
  );
};

export default Logo;
