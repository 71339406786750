import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Radio,
  RadioGroup,
  Typography,
  makeStyles,
  FormControlLabel,
  FormControl,
  FormGroup,
  Checkbox
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useRecoilState, useRecoilCallback } from 'recoil';
import { getCustomersPartialAtom } from 'src/app-data/atoms/customers-atom';
import { reportCustomerHistoryAtom } from 'src/app-data/atoms/reports-atom';
import { getCountriesAtom } from 'src/app-data/atoms/locations-atom';
import { httpClient } from 'src/lib/api-factory';
import { formatDate, formatDateMinusDays } from  'src/lib/date-functions';
import { exportToExcel } from 'src/lib/export-to-excel';

const useStyles = makeStyles((theme) => ({
  root: {},
  exportButtonContainer: {
    marginBottom: theme.spacing(3)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radioLabel: {
    fontSize: '14px'
  },
  listStyle: {
    fontSize: '16px',
    //padding: theme.spacing(2)
  }
}));

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();

  const [displayMessage, updateDisplayMessage] = useState({
    message: "",
    show: false,
    severity: "", //error, warning, info, success
    showAction: false
  });

  const [selectedValue, setSelectedValue] = useState('0');
  const [checked, setChecked] = useState(true);
  const [_isSubmitting, updateIsSubmitting] = useState(false);
  const [_isExporting, setIsExporting] = useState(false);
  const [customers, setCustomers] = useRecoilState(getCustomersPartialAtom); 
  const [countries, setCountries] = useRecoilState(getCountriesAtom); 
  const [reportData, setReportData] = useRecoilState(reportCustomerHistoryAtom);

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
  };

  const getCustomers = useRecoilCallback(() => async () => {
    try {
        const results = await httpClient().get("/customers");
        return results.data;
    }
    catch(error) {
        return [];
    }    
  });

  const getCountries = useRecoilCallback(() => async () => { 
    try {
        const results = await httpClient().get("/countries");
        var countries = [];

        if(results && results.data && results.data.length > 0) {
          results.data.forEach(item => {
            if(item.countryName === 'United States of America') {
              countries.unshift(item);
            }
            else {
              countries.push(item);
            }
          });
          
          return countries;
        }
        else {
          return results.data;
        }
    }
    catch(error) {
        return [];
    }    
  });

  const fetchReportData = useRecoilCallback(() => async (reqData) => {
    try {
        const results = await httpClient().post("/reports/customer-history", reqData);
        return results.data;
    }
    catch(error) {
      console.log(error);
      return [];
    }    
  });

  const exportToExcelHandler = () => { 
    if(reportData && reportData.length > 0) {
      var exportData  = [];

      reportData.map(item => 
        exportData.push({
          "Serial Number": item.serialNumber,
          "Trode": `${item.trodeTypeName} ${item.trodeTypeSize}${item.trodeTypeMeasurement}`,
          "Date Shipped": formatDate(item.dateSentOut),
          "Date Received": item.dateReceivedIn ? formatDate(item.dateReceivedIn) : "Out with customer",
          "Customer #": item.customerNumber,
          "Customer Name": item.customerName,
          "Location": item.stateName ? `${item.stateName}, USA` : item.countryName
        })
      );

      setIsExporting(true);
      exportToExcel(exportData, 'customer-history-report');
      setIsExporting(false);
    }
  };

  const handleSubmit = (values, validateFunc, setTouched) => { 
    const { customerAccountId, countryId, startDate, endDate } = values;

    (async () => {
      try {
        const valError = await validateFunc();

        if(Object.keys(valError).length > 0) {
          setTouched(valError);
          updateIsSubmitting(false);
        }
        else {
          updateIsSubmitting(true);

          var reportData = await fetchReportData({
            startDate: startDate,
            endDate: endDate,
            type: selectedValue === '0' ? 'cust' : 'ctry',
            id: selectedValue === '0' ? customerAccountId : countryId,
            outstandingOnly: checked
          });

          setReportData(reportData);
          
          if(reportData.length > 0) {
            updateDisplayMessage({ 
              message: "",
              show: false,
              severity: "success"
            });
          }
          else {
            updateDisplayMessage({ 
              message: "No records were found. Revise your search criteria and try searching again.",
              show: true,
              severity: "warning"
            });
          }
        }

        updateIsSubmitting(false);
      }
      catch(error) {
        updateDisplayMessage({ 
          message: (error && error.message) || "There was an error fetching the data",
          show: true,
          severity: "error"
        });
      }
    })();
  }

  useEffect(() => {
    (async () => {
      try {
        const customersResponse = await getCustomers();
        setCustomers(customersResponse);

        const countriesResponse = await getCountries();
        setCountries(countriesResponse);
      }
      catch(error) {

      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <Formik
      initialValues={{
          customerAccountId: '0',
          countryId: '0',
          startDate: formatDateMinusDays(Date.now(), 30),
          endDate: formatDate(Date.now())
      }}
      validationSchema={Yup.object().shape({
          customerAccountId: selectedValue === '0' && Yup.string().notOneOf(['0', '', 0], 'Customer is required').required('Customer is required'),
          countryId: selectedValue === '1' && Yup.string().notOneOf(['0', '', 0], 'Country is required').required('Country is required'),
          startDate: Yup.date().required('Start Date is required'),
          endDate: Yup.date()
            .min(Yup.ref('startDate'), "End Date can not be before Start Date")
            .required("Invalid date (ddmmyyyy)")
      })}
    >
      {({errors, handleBlur, handleChange, handleReset, validateForm, setTouched, touched, values, initialValues}) => (
      <>
        <div className={clsx(classes.root, className)}
          {...rest}
        >
          <Box display="flex" justifyContent="flex-end" className={classes.exportButtonContainer}>
            <Button color="primary" variant="contained" onClick={exportToExcelHandler} disabled={_isExporting}>
              { _isExporting ? 'Exporting...' : 'Export To Excel' }
            </Button>
          </Box>

          <Card>
            <CardHeader
              title={`Customer History Report`}
              subheader="Search history by customer or country."
            />

            <Divider />

            <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={3} xs={12}>
                    <RadioGroup row aria-label="position" name="position" defaultValue="0">
                      <FormControlLabel
                        value="0"
                        control={
                          <Radio
                            checked={selectedValue === '0'}
                            onChange={handleRadioChange}
                            value="0"
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'Customer' }}
                            size={'small'}
                          />
                        }
                        label={<Typography className={classes.radioLabel}>Customer</Typography>}
                        labelPlacement="end"
                      />

                      <FormControlLabel
                        value="1"
                        control={
                          <Radio
                            checked={selectedValue === '1'}
                            onChange={handleRadioChange}
                            value="1"
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'Country' }}
                            size={'small'}
                          />
                        }
                        label={<Typography className={classes.radioLabel}>Country</Typography>}
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormControl component="fieldset">    
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value={checked}
                          control={
                            <Checkbox
                              defaultChecked
                              color="primary"
                              inputProps={{ 'aria-label': 'secondary checkbox' }}
                              value={checked}
                              onChange={handleCheckChange}
                            />
                          }
                          label="Outstanding Only"
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  { selectedValue && selectedValue === '0' &&
                  <Grid item xs={12}>
                    <Autocomplete
                      id="combo-box-customers"
                      options={customers}
                      fullWidth
                      getOptionLabel={(option) => `${option.customerName} #${option.customerNumber}`}
                      onChange={(event, value) => values.customerAccountId = value && value.customerAccountId}
                      renderOption={(option) => (
                        <React.Fragment>
                          <span style={{width: 60}}>#{option.customerNumber}</span>
                          {option.customerName}
                        </React.Fragment>
                        )} 
                        renderInput={
                          (params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Select Customer"
                              name="customerAccountId"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              required
                              SelectProps={{ native: true }}
                              value={values.customerAccountId}
                              variant="outlined"
                              error={Boolean(touched.customerAccountId && errors.customerAccountId)}
                              helperText={touched.customerAccountId && errors.customerAccountId}
                              style={{maxWidth: 500}}
                            />
                          )
                        }
                    />
                  </Grid>
                  }

                  { selectedValue && selectedValue === '1' &&
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Select A Country"
                      name="countryId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      select
                      SelectProps={{ native: true }}
                      value={values.countryId}
                      variant="outlined"
                      error={Boolean(touched.countryId && errors.countryId)}
                      helperText={touched.countryId && errors.countryId}
                      style={{maxWidth: 500}}
                      InputProps={{
                        classes: {
                          input: classes.listStyle,
                        },
                      }}
                    >
                      <option key={`size_0_0`} value="0">
                          Select A Country
                      </option>

                      {countries && countries.length > 0 && countries.map((option) => (
                      <option key={`country_${option.countryId}`} value={option.countryId}>
                          {option.countryName}
                      </option>
                      ))}
                    </TextField>
                  </Grid>
                  }

                  <Grid item md={3} xs={12}>
                      <TextField
                          fullWidth
                          label="Start Date"
                          name="startDate"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          type="date"
                          SelectProps={{ native: true }}
                          value={values.startDate}
                          variant="outlined"
                          error={Boolean(touched.startDate && errors.startDate)}
                          helperText={touched.startDate && errors.startDate}
                          className={classes.textField}
                          InputLabelProps={{
                              shrink: true
                          }}
                      />
                  </Grid>

                  <Grid item md={3} xs={12}>
                      <TextField
                          fullWidth
                          label="End Date"
                          name="endDate"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          type="date"
                          SelectProps={{ native: true }}
                          value={values.endDate}
                          variant="outlined"
                          error={Boolean(touched.endDate && errors.endDate)}
                          helperText={touched.endDate && errors.endDate}
                          className={classes.textField}
                          InputLabelProps={{
                              shrink: true
                          }}
                      />
                  </Grid>

                  {displayMessage.show &&
                  <Grid item md={12} xs={12}>
                    <Alert severity={displayMessage.severity}>
                      {displayMessage.message}
                    </Alert>
                  </Grid>
                  }
                </Grid>
            </CardContent>

            <Divider />

            <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
            >
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleSubmit(values, validateForm, setTouched)}
                    disabled={_isSubmitting}
                    type="submit"
                >
                    { _isSubmitting ? 'Fetching Data...' : 'Submit' }
                </Button>
            </Box>
        </Card>
        </div>
      </>
      )}
    </Formik>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
