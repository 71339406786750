import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Alert from '@material-ui/lab/Alert';

import { useRecoilState, useRecoilCallback } from 'recoil';
import { reportOutstandingTrodesAtom } from 'src/app-data/atoms/reports-atom';
import { httpClient } from 'src/lib/api-factory';
import { formatDate, formatDateMinusDays } from  'src/lib/date-functions';
import { exportToExcel } from 'src/lib/export-to-excel';

const useStyles = makeStyles((theme) => ({
  root: {},
  exportButtonContainer: {
    marginBottom: theme.spacing(3)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radioLabel: {
    fontSize: '14px'
  },
  listStyle: {
    fontSize: '16px',
    //padding: theme.spacing(2)
  }
}));

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();

  const [displayMessage, updateDisplayMessage] = useState({
    message: "",
    show: false,
    severity: "", //error, warning, info, success
    showAction: false
  });

  const [_isSubmitting, updateIsSubmitting] = useState(false);
  const [_isExporting, setIsExporting] = useState(false);
  const [reportData, setReportData] = useRecoilState(reportOutstandingTrodesAtom);

  const fetchReportData = useRecoilCallback(() => async (reqData) => {
    try {
        const results = await httpClient().post("/reports/outstanding-trodes", reqData);
        return results.data;
    }
    catch(error) {
      console.log(error);
      return [];
    }    
  });

  const exportToExcelHandler = () => { 
    if(reportData && reportData.length > 0) {
      var exportData  = [];

      reportData.map(item => 
        exportData.push({
          "Date Shipped": formatDate(item.dateSentOut),
          "Customer Name": item.customerName,
          "Customer #": item.customerNumber,
          "Location": item.stateName ? `${item.stateName}, USA` : item.countryName,
          "Trode": `${item.trodeTypeName} ${item.trodeTypeSize}${item.trodeTypeMeasurement}`,
          "Serial Number": item.serialNumber
        })
      );

      setIsExporting(true);
      exportToExcel(exportData, 'outstanding-trodes-report');
      setIsExporting(false);
    }
  };

  const handleSubmit = (values, validateFunc, setTouched) => { 
    const { startDate, endDate } = values;

    (async () => {
      try {
        const valError = await validateFunc();

        if(Object.keys(valError).length > 0) {
          setTouched(valError);
          updateIsSubmitting(false);
        }
        else {
          updateIsSubmitting(true);

          var reportData = await fetchReportData({
            startDate: startDate,
            endDate: endDate
          });

          setReportData(reportData);
          
          if(reportData.length > 0) {
            updateDisplayMessage({ 
              message: "",
              show: false,
              severity: "success"
            });
          }
          else {
            updateDisplayMessage({ 
              message: "No records were found. Revise your search criteria and try searching again.",
              show: true,
              severity: "warning"
            });
          }
        }

        updateIsSubmitting(false);
      }
      catch(error) {
        updateDisplayMessage({ 
          message: (error && error.message) || "There was an error fetching the data",
          show: true,
          severity: "error"
        });
      }
    })();
  }
  
  return (
    <Formik
      initialValues={{
          startDate: formatDateMinusDays(Date.now(), 30),
          endDate: formatDate(Date.now())
      }}
      validationSchema={Yup.object().shape({
          startDate: Yup.date().required('Start Date is required'),
          endDate: Yup.date()
            .min(Yup.ref('startDate'), "End Date can not be before Start Date")
            .required("Invalid date (ddmmyyyy)")
      })}
    >
      {({errors, handleBlur, handleChange, handleReset, validateForm, setTouched, touched, values, initialValues}) => (
      <>
        <div className={clsx(classes.root, className)}
          {...rest}
        >
          <Box display="flex" justifyContent="flex-end" className={classes.exportButtonContainer}>
            <Button color="primary" variant="contained" onClick={exportToExcelHandler} disabled={_isExporting}>
              { _isExporting ? 'Exporting...' : 'Export To Excel' }
            </Button>
          </Box>

          <Card>
            <CardHeader
              title={`Outstanding Trodes Report`}
              subheader="Select a start and end date."
            />

            <Divider />

            <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={3} xs={12}>
                      <TextField
                          fullWidth
                          label="Start Date"
                          name="startDate"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          type="date"
                          SelectProps={{ native: true }}
                          value={values.startDate}
                          variant="outlined"
                          error={Boolean(touched.startDate && errors.startDate)}
                          helperText={touched.startDate && errors.startDate}
                          className={classes.textField}
                          InputLabelProps={{
                              shrink: true
                          }}
                      />
                  </Grid>

                  <Grid item md={3} xs={12}>
                      <TextField
                          fullWidth
                          label="End Date"
                          name="endDate"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          type="date"
                          SelectProps={{ native: true }}
                          value={values.endDate}
                          variant="outlined"
                          error={Boolean(touched.endDate && errors.endDate)}
                          helperText={touched.endDate && errors.endDate}
                          className={classes.textField}
                          InputLabelProps={{
                              shrink: true
                          }}
                      />
                  </Grid>

                  {displayMessage.show &&
                  <Grid item md={12} xs={12}>
                    <Alert severity={displayMessage.severity}>
                      {displayMessage.message}
                    </Alert>
                  </Grid>
                  }
                </Grid>
            </CardContent>

            <Divider />

            <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
            >
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleSubmit(values, validateForm, setTouched)}
                    disabled={_isSubmitting}
                    type="submit"
                >
                    { _isSubmitting ? 'Fetching Data...' : 'Submit' }
                </Button>
            </Box>
        </Card>
        </div>
      </>
      )}
    </Formik>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
